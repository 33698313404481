
import './App.css';
import logo from './images/Pulsecraft Innovations.png'
import a from './images/Frame 887.png'
import b from './images/Frame 888.png'
import c from './images/Frame 889.png'
import d from './images/Frame 890.png'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const [email, setemail] = useState('')
  const send = (e) => {
    e.preventDefault();
    if (email === '') {

    } else {

      emailjs
        .sendForm('service_o7dtp1a', 'template_m3zpfkk', e.target, 'fVsE17VJyVluhQ8s5')
        .then((response) => {
          console.log('Email sent:', response);
          toast.success("Thank You")
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          toast.error("Something Went Wrong")
        });
    }
  }
  const [targetDate, setTargetDate] = useState(() => {
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 din ka time calculate karna
    return targetDate;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const difference = targetDate.getTime() - currentTime;
      if (difference <= 0) {
        clearInterval(interval);
        return;
      }
      setTargetDate(new Date(currentTime + difference));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);
  return (
    <>
      <Toaster

        position="top-right"
        reverseOrder={false}
      />
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex flex-column justify-content-between min-vh-75'>
          <img src={logo} alt="" className='logo' />
          <div className="text-center">
            <p className="heading my-0">
              Coming Soon
            </p>
            <p className="para">
              In the meantime, Sign up for our monthly newsletter
              <br />
              to stay up to date.
            </p>
            <form onSubmit={send}>
              <div class="input-group flex-nowrap">
                <input type="email" class="form-control" placeholder="Email Address" aria-label="Username" aria-describedby="addon-wrapping" />
                <span class="input-group-text" type={'submit'} id="addon-wrapping">Subscribe</span>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col-12">
              {/* <FlipClockCountdown to={targetDate.getTime()} /> */}
              {/* <FlipClockCountdown to={new Date().getTime() + 720 * 3600 * 1000 + 5000} />; */}
            </div>
          </div>
          <div className='text-center'>
            <img src={a} alt="" className='social' />
            <img src={b} alt="" className='social' />
            <img src={c} alt="" className='social' />
            <img src={d} alt="" className='social' />

          </div>
        </div>
      </div>
    </>
  );
}

export default App;
